import { call, put } from 'redux-saga/effects';

import { authChannelModel } from '../../../entities/auth-channel';
import { authModel } from '../../../entities/auth-operation';
import { auth } from '../../../shared/config/firebase';
import { signInWithEmailAndPassword as signInWithEmailAndPasswordFB } from 'firebase/auth';
import {
  QUERY_PARAM_EXTERNAL_PAGE_ID,
  QUERY_PARAM_PAGE_INVITE_ID,
  QUERY_PARAM_TEAM_INVITE_ID,
} from '../../accept-team-invitation';
import { getQueryParam } from '../../../shared/lib';

export function* signInWithEmailAndPassword(
  signInWithEmailAndPasswordAction: ReturnType<
    typeof authModel.actions.signUpClick
  >
) {
  const {
    payload: { email, password },
  } = signInWithEmailAndPasswordAction;

  yield call(
    authModel.sagas.authOperation,
    `Invalid email or password. Please check your details and try again, or use the 'Forgot Password?' option.`,
    function* () {
      const teamInviteId = getQueryParam(QUERY_PARAM_TEAM_INVITE_ID);
      const pageInviteId = getQueryParam(QUERY_PARAM_PAGE_INVITE_ID);
      const externalPageId = getQueryParam(QUERY_PARAM_EXTERNAL_PAGE_ID);

      yield signInWithEmailAndPasswordFB(auth, email, password);

      yield put(authModel.actions.setIsWaitingToRedirectAfterAuth(true));

      if (externalPageId) {
        yield put(
          authChannelModel.chan,
          authChannelModel.loggedIn({
            redirectTo: 'join-page-as-guest',
            data: { externalPageId },
          })
        );
      }

      if (pageInviteId) {
        yield put(
          authChannelModel.chan,
          authChannelModel.loggedIn({
            redirectTo: 'accept-page-invitation',
            data: { pageInviteId, teamInviteId: teamInviteId ?? '' },
          })
        );
        return;
      }

      yield put(
        authChannelModel.chan,
        authChannelModel.loggedIn({
          redirectTo: teamInviteId ? 'accept-team-invitation' : 'workspace',
          data: teamInviteId ? { teamInviteId } : undefined,
        })
      );
    }
  );
}
