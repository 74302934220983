import { pagesModel } from '../../../features/pages';
import { redirect } from '../../../entities/history';
import { select, call, put } from 'redux-saga/effects';
import { Folder } from '@distribute/shared/types';
import { foldersModel } from '../../../features/folders';
import { matchParams } from '../../../shared/lib';
import { getQueryParam } from '../../../shared/lib';
import { createPageFromGongTranscript } from './sagas';

export function* workspaceFolderSaga(location: Location, route: string) {
  const { pathname } = location;
  const params = matchParams(pathname, route);
  const folders: Folder[] = yield select(foldersModel.selectors.selectFolders);

  const folder = folders.find(
    (folder) => folder.sequenceNumber === params?.sequenceNumber
  );
  if (!folder) {
    yield call(redirect.toWorkspace);
    return;
  }
  yield put(foldersModel.actions.setCurrentFolder(folder));
  yield put(pagesModel.actions.setCurrentPage(undefined));
  yield put(pagesModel.actions.setCurrentContentId(undefined));

  const gongCallId = getQueryParam('gongCallId');
  if (gongCallId) {
    yield call(createPageFromGongTranscript, gongCallId);
  }
}
