import { call, put, select } from 'redux-saga/effects';
import {
  GONG_ACCESS_TOKEN_STORAGE_KEY,
  GONG_BASE_URL,
} from '../../../../shared/constants';
import { gongApi } from '../../../../shared/api';
import { checkGongAccessTokenExpiration } from './checkGongAccessTokenExpiration';
import { GetFormattedCallTranscriptsResponseType } from '@distribute/shared/api-types/gong';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { gongModel } from '..';
import { teamsModel } from '../../../../features/teams';
import { RT } from '../../../../shared/types';
import { getTeamPermissions } from '../../../../features/teams/lib';
import { authUserModel } from '../../../auth-user';

export function* getCallTranscripts() {
  try {
    const currentUser: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);
    const currentTeamMembers: RT<
      typeof teamsModel.selectors.selectCurrentTeamMembers
    > = yield select(teamsModel.selectors.selectCurrentTeamMembers);

    const { isGuest } = getTeamPermissions(currentUser, currentTeamMembers);

    if (isGuest) {
      return;
    }

    yield put(gongModel.actions.setIsLoadingTranscripts(true));
    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const isPersonalGongAccountConnected: RT<
      typeof gongModel.selectors.selectIsConnected
    > = yield select(gongModel.selectors.selectIsConnected);

    if (!currentTeam.isGongConnected && !isPersonalGongAccountConnected) {
      return;
    }

    if (isPersonalGongAccountConnected) {
      yield call(checkGongAccessTokenExpiration);
    }

    const token = localStorage.getItem(GONG_ACCESS_TOKEN_STORAGE_KEY) as string;
    const url = localStorage.getItem(GONG_BASE_URL) as string;
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 21);
    const toDate = new Date();

    const transcripts: GetFormattedCallTranscriptsResponseType = yield call(
      gongApi.getCallTranscripts,
      {
        url,
        toDate,
        userToken: token,
        fromDate,
        teamId: currentTeam.id,
      }
    );
    yield put(gongModel.actions.setCallTranscriptsData(transcripts));
  } catch {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to get Gong call transcripts')
      )
    );
  } finally {
    yield put(gongModel.actions.setIsLoadingTranscripts(false));
  }
}
