import { ReactNodeViewRenderer } from '@tiptap/react';
import { TimelineItemNodeView } from './TimelineItem.renderer';
import { TimelineItemExt } from '@distribute/shared/generate-html';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { FC } from 'react';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    timelineItem: {
      insertTimelineItem: () => ReturnType;
      toggleTimelineItem: () => ReturnType;
    };
  }
}
export const TimelineItem = TimelineItemExt.extend({
  addCommands() {
    return {
      toggleTimelineItem:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
      insertTimelineItem:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            content: [
              {
                type: 'heading',
                attrs: { level: 4, textAlign: 'left' },
              },
              {
                type: 'paragraph',
              },
            ],
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(TimelineItemNodeView as FC);
  },

  addProseMirrorPlugins() {
    const dragImage = new Image();
    dragImage.src = `https://storage.googleapis.com/distribute-production-storage/general/dots-drag.png`;

    return [
      new Plugin({
        key: new PluginKey('timelineItem'),
        props: {
          handleDOMEvents: {
            dragstart: (_, e) => {
              const target = e.target as HTMLElement;
              const parent = target.parentElement as HTMLElement;
              if (!parent.classList.contains('node-timelineItem')) {
                return;
              }

              // Create a clone of the target element
              const clone = target.cloneNode(true) as HTMLElement;
              clone.style.background = '#FFFFFF';
              clone.style.position = 'absolute';
              clone.style.zIndex = '1000';
              clone.style.pointerEvents = 'none';
              clone.style.opacity = '0.8';
              clone.style.width = `${target.offsetWidth}px`;
              clone.style.height = `${target.offsetHeight}px`;
              document.body.appendChild(clone);

              e.dataTransfer?.setDragImage(dragImage, 0, 0);

              const dragOverHandler = (e: DragEvent) => {
                clone.style.left = `${e.clientX + 10}px`;
                clone.style.top = `${e.clientY + 10}px`;
              };

              document.addEventListener('dragover', dragOverHandler);

              target.addEventListener(
                'dragend',
                () => {
                  document.body.removeChild(clone);
                  document.removeEventListener('dragover', dragOverHandler);
                },
                { once: true }
              );
            },
          },
        },
      }),
    ];
  },
});
