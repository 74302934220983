import { DocumentContent } from './document-content';
import { Team } from './team';
import { User } from './user';

export enum TemplateSharingAccess {
  PERSONAL = 'personal',
  TEAM = 'team',
}

export enum TemplateCreationPhase {
  READY_TO_USE = 'ready-to-use',
  DRAFT = 'draft',
}

export enum TemplateBgColor {
  ERROR = 'error',
  ORANGE = 'orange',
  WARNING = 'warning',
  SUCCESS = 'success',
  PRIMARY = 'primary',
  BLUE = 'blue',
  INDIGO = 'indigo',
  PURPLE = 'purple',
  FUCHSIA = 'fuchsia',
  PINK = 'pink',
}

export enum TemplateCategory {
  COMMUNITY = 'community',
  LEAD_GENERATION = 'leadGeneration',
  SALES_ROOMS = 'salesRooms',
  CASE_STUDIES = 'caseStudies',
  SOCIAL_SELLING = 'socialSelling',
  MEETING_FOLLOW_UP = 'meetingFollowUp',
  BUSINESS_CASES = 'businessCases',
  ONBOARDING = 'onboarding',
  EVENTS = 'events',
  SALES_ASSETS = 'salesAssets',
  LEAD_MAGNETS = 'leadMagnets',
  SALES_PROPOSAL = 'salesProposal',
  MUTUAL_ACTION_PLAN = 'mutualActionPlan',
  COMPETITIVE_COMPARISON = 'competitiveComparison',
  CUSTOMER_TESTIMONIALS = 'customerTestimonials',
  PRICING_AND_PACKAGING = 'pricingAndPackaging',
  PRODUCT_OVERVIEW = 'productOverview',
  SERVICE_AND_SUPPORT = 'serviceAndSupport',
  PRE_CALL = 'preCall',
  POST_DEMO = 'postDemo',
  INTERNAL_DEAL_REVIEW = 'internalDealReview',
  POST_CALL = 'postCall',
  MEETINGS = 'meetings',
  OPEN_PIPELINE = 'openPipeline',
  POST_SALE = 'postSale',
  CUSTOMERS = 'customers',
  COACHING = 'coaching',
  BEST_PRACTICES = 'bestPractices',
  DEAL_STRATEGY = 'dealStrategy',
  INTERNAL_UPDATES = 'internalUpdates',
  PROSPECTING = 'prospecting',
  IN_DISCUSSION = 'inDiscussion',
  CLOSING = 'closing',
  IMPLEMENTATION = 'implementation',
  CUSTOMER_SUCCESS = 'customerSuccess',
  UPSELL_AND_CROSS_SELL = 'upsellAndCrossSell',
  WINBACK = 'winback',
}

export type Template = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  description: string;
  owner: User;
  team: Team;
  usedCount: number;
  sharingAccess: TemplateSharingAccess;
  content: DocumentContent;
  category: TemplateCategory | null;
  isDistribute: boolean | null;
  creationPhase: TemplateCreationPhase;
  bgColor: TemplateBgColor;
  isIncludeConversionTools: boolean | null;
  isSinglePage: boolean;
};
