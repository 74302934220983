import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { templatesModel } from '../../model';
import { TemplatesConfigEnum, TemplatesSwitcherEnum } from '../../lib';
import { CreateBlankPage } from './CreateBlankPage';
import { NoTemplatesFound } from './NoTemplatesFound';
import {
  TemplateCategory,
  TemplateCreationPhase,
  TemplateSharingAccess,
} from '@distribute/shared/types';
import { TemplateItem } from './TemplateItem';
import { TemplatesList } from './TemplatesList';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { CreateWithAI } from './CreateWithAI';
import { createPageModalModel } from '../../../../entities/create-page-modal';
import {
  CreatePageStepsEnum,
  PageCreationWorkflowsEnum,
} from '../../../../entities/create-page-modal';
import { useTemplatesModalContext } from '../../lib';

type Props = {
  isHideCreateBlankPage?: boolean;
  onChoose(): void;
  sortMethod: TemplatesSwitcherEnum;
};

export const TemplatesSection: React.FC<Props> = ({
  isHideCreateBlankPage,
  onChoose,
  sortMethod,
}) => {
  const dispatch = useDispatch();
  const currentTemplatesFolder = useSelector(
    templatesModel.selectors.selectCurrentTemplatesFolder
  );

  const templates = useSelector(
    templatesModel.selectors.selectTemplates
  ).filter((t) => t.creationPhase !== TemplateCreationPhase.DRAFT);

  const searchQuery = useSelector(templatesModel.selectors.selectSearchQuery);

  const sortedTemplates = useMemo(() => {
    if (sortMethod === TemplatesSwitcherEnum.POPULAR) {
      return [...templates].sort((a, b) => b.usedCount - a.usedCount);
    }

    if (sortMethod === TemplatesSwitcherEnum.NEW) {
      return [...templates].sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
    }

    return [...templates];
  }, [sortMethod, templates]);

  const foundTemplates = useMemo(
    () =>
      templates.filter((template) =>
        new RegExp(searchQuery, 'ig').test(template.name)
      ),
    [searchQuery, templates]
  );

  const personalTemplates = useMemo(
    () =>
      templates.filter(
        (el) =>
          el.sharingAccess === TemplateSharingAccess.PERSONAL &&
          el.category === null
      ),
    [templates]
  );
  const teamTemplates = useMemo(
    () =>
      templates.filter(
        (el) =>
          el.sharingAccess === TemplateSharingAccess.TEAM &&
          el.category === null
      ),
    [templates]
  );

  const recentTemplates = useSelector(
    templatesModel.selectors.selectRecentTemplates
  );
  const topTemplates = useSelector(templatesModel.selectors.selectTopTemplates);

  const categoriesTemplatesMap = templates.reduce(
    (prev: { [key in TemplateCategory]?: TemplateExtended[] }, template) => {
      if (!template.category) return prev;
      if (prev[template.category]) {
        prev[template.category]?.push(template);
      } else {
        prev[template.category] = [template];
      }
      return prev;
    },
    {}
  );

  const templatesDictionary = useMemo(
    () => ({
      ...categoriesTemplatesMap,
      [TemplatesConfigEnum.ALL]: sortedTemplates,
      [TemplatesConfigEnum.PERSONAL]: personalTemplates,
      [TemplatesConfigEnum.TEAM]: teamTemplates,
      [TemplatesConfigEnum.RECENT]: recentTemplates,
      [TemplatesConfigEnum.TOP]: topTemplates,
    }),
    [
      categoriesTemplatesMap,
      personalTemplates,
      recentTemplates,
      teamTemplates,
      sortedTemplates,
      topTemplates,
    ]
  );

  const templatesModalContext = useTemplatesModalContext();
  const templatesToRender = templatesDictionary[currentTemplatesFolder]?.filter(
    ({ isSinglePage }) =>
      isSinglePage === templatesModalContext.isSinglePageTemplate
  );
  const currentSelectedTemplate = useSelector(
    templatesModel.selectors.selectCurrentSelectedTemplate
  );

  useEffect(() => {
    if (!currentSelectedTemplate) {
      if (searchQuery && foundTemplates?.length) {
        dispatch(templatesModel.actions.setRenderedTemplates(foundTemplates));
        return;
      }

      dispatch(
        templatesModel.actions.setRenderedTemplates(templatesToRender || [])
      );
    }
  }, [
    dispatch,
    foundTemplates,
    searchQuery,
    templatesToRender,
    currentSelectedTemplate,
  ]);

  const handleBlankPageCreate = () => {
    dispatch(templatesModel.actions.setCurrentSelectedTemplate(undefined));
    onChoose();
  };

  const handleCreatePageWithAI = () => {
    dispatch(
      createPageModalModel.actions.openPageCreationModal({
        currentStep: CreatePageStepsEnum.BRANDING,
        creationWorkflow: PageCreationWorkflowsEnum.WITH_AI,
        previousStepsStack: [CreatePageStepsEnum.TEMPLATE],
      })
    );
    onChoose();
  };

  if (searchQuery && foundTemplates?.length) {
    return (
      <div className="grid grid-cols-3 gap-6 p-8 pt-2 overflow-x-hidden overflow-y-auto max1280:grid-cols-2 md:grid-cols-1 grow-1 place-content-start">
        {foundTemplates.map((template, i) => (
          <TemplateItem
            isSettingsVisible={false}
            template={template}
            key={template.id}
            onChoose={onChoose}
            searchQuery={searchQuery}
            index={i}
          />
        ))}
      </div>
    );
  }

  if (searchQuery && !foundTemplates?.length) {
    return <NoTemplatesFound type="search" />;
  }

  if (
    currentTemplatesFolder === TemplatesConfigEnum.TEAM &&
    !teamTemplates.length
  ) {
    return <NoTemplatesFound type="team" />;
  }

  if (
    currentTemplatesFolder === TemplatesConfigEnum.PERSONAL &&
    !personalTemplates.length
  ) {
    return <NoTemplatesFound type="personal" />;
  }

  if (
    currentTemplatesFolder === TemplatesConfigEnum.RECENT &&
    !templatesToRender?.length
  ) {
    return <NoTemplatesFound type="recent" />;
  }

  if (
    !templatesToRender?.length &&
    currentTemplatesFolder !== TemplatesConfigEnum.ALL
  ) {
    return <NoTemplatesFound type="other" />;
  }

  return (
    <div className="grid flex-grow grid-cols-3 gap-6 p-8 pt-2 overflow-x-hidden overflow-y-auto max1280:grid-cols-2 md:grid-cols-1 place-content-start">
      {currentTemplatesFolder === TemplatesConfigEnum.ALL &&
        !isHideCreateBlankPage && (
          <>
            <CreateBlankPage onClick={handleBlankPageCreate} />
            <CreateWithAI onClick={handleCreatePageWithAI} />
          </>
        )}
      <TemplatesList
        templates={templatesToRender ?? []}
        onChoose={onChoose}
        searchQuery={searchQuery}
      />
    </div>
  );
};
