import { TemplatesConfigEnum } from '..';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

export const getDistributeTemplatesByCategory = (
  templates: TemplateExtended[],
  groupItemKey: TemplatesConfigEnum,
  isSinglePageTemplate: boolean
) => {
  return templates.filter(
    (el) =>
      el.category === groupItemKey && el.isSinglePage === isSinglePageTemplate
  );
};
