import { Emoji } from 'emoji-picker-react';
import React from 'react';
import { TemplateItemSettingsDropdown } from './TemplateItemSettingsDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { templatesModel } from '../../model';
import { CreatedByRow, TemplatePreviewRaw } from '.';
import { Button, Icon } from '../../../../shared/ui';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { generatePath } from 'react-router-dom';
import {
  TEMPLATE_EDITOR_PAGE_ROUTE,
  redirectActions,
} from '../../../../entities/history';
import { IconMap } from '../../../../shared/sprite';
import { pagesModel } from '../../../pages';
import { TemplateCreationPhase } from '@distribute/shared/types';
import { templateBgStyles } from '../../lib';
import { cn } from '@distribute/frontend/utils';
import { createPageModalModel } from '../../../../entities/create-page-modal';
import { PageCreationWorkflowsEnum } from '../../../../entities/create-page-modal';

type Props = {
  template: TemplateExtended;
  onChoose(): void;
  searchQuery?: string;
  index: number;
  isTemplatesPage?: boolean;
  isSettingsVisible?: boolean; // Prevent editing in modal
};

export const TemplateItem: React.FC<Props> = ({
  template,
  onChoose,
  isTemplatesPage = false,
  isSettingsVisible = true,
}) => {
  const dispatch = useDispatch();

  const handleTemplateEdit = () => {
    dispatch(
      redirectActions.fromWorkspaceWithBackUrl({
        path: generatePath(TEMPLATE_EDITOR_PAGE_ROUTE, {
          sequenceNumber: template.id,
        }),
      })
    );
  };

  const handleTemplateDuplicate = () => {
    dispatch(
      templatesModel.actions.duplicateTemplate({
        templateId: template.id,
      })
    );
  };

  const handleTemplatePreview = () => {
    dispatch(templatesModel.actions.setTemplateModalOpen(true));
    dispatch(templatesModel.actions.setCurrentSelectedTemplate(template));
    dispatch(templatesModel.actions.setPreviewMode(true));
  };

  const handleTemplateSelect = () => {
    dispatch(templatesModel.actions.setCurrentSelectedTemplate(template));
    dispatch(
      createPageModalModel.actions.setCreationWorkflow(
        PageCreationWorkflowsEnum.FROM_TEMPLATE
      )
    );
    onChoose();
  };

  const isLoading = useSelector(pagesModel.selectors.selectCreatePageIsLoading);

  return (
    <div
      className={cn(
        '2xl:w-auto p-3 border border-gray-300 cursor-pointer rounded-xl h-fit group hover:shadow-md max-w-[416px]',
        {
          'w-1/3 ': isTemplatesPage,
        }
      )}
    >
      <div className="h-[164px] border border-light-6 rounded-xl overflow-hidden relative">
        <div className="absolute z-10 flex flex-col items-center justify-center w-full h-full gap-2 transition-all duration-100 ease-linear opacity-0 group-hover:opacity-100 sm:hidden">
          {template.creationPhase === TemplateCreationPhase.DRAFT &&
          template.isOwner ? (
            <Button
              variant="text"
              color="primary"
              className="w-45 !h-11 text-md"
              onClick={handleTemplateEdit}
            >
              <Icon glyph={IconMap.Edit02} width={20} className="mr-1.5" />
              Edit draft
            </Button>
          ) : (
            <>
              <Button
                variant="text"
                color="primary"
                className="w-45 !h-11 text-md"
                onClick={handleTemplateSelect}
                disabled={isLoading}
              >
                Use Template
              </Button>

              <Button
                variant="text"
                color="secondary"
                className="w-45 !h-9 text-sm"
                onClick={handleTemplatePreview}
              >
                <Icon glyph={IconMap.Eye} width={20} className="mr-1.5" />
                Preview
              </Button>
            </>
          )}
        </div>
        <div
          className={cn(
            'w-full h-full px-6 pt-5 group-hover:blur sm:group-hover:blur-none flex justify-center',
            `${templateBgStyles[template.bgColor].bgColor}`,
            {
              'justify-center': template.isSinglePage,
              'justify-between gap-3': !template.isSinglePage,
            }
          )}
        >
          <div
            className={cn(
              'w-8 h-[calc(100%-16px)] self-end grow -ml-6 shadow-md bg-base-white/60 rounded-tr-xl',
              { hidden: template.isSinglePage }
            )}
          ></div>
          <div className="relative w-full h-full shadow-md bg-base-white rounded-t-xl max-w-[216px] min-w-[216px]">
            <TemplatePreviewRaw
              style={{
                transform: `scale(0.22, 0.22)`,
                transformOrigin: '0 0',
              }}
              template={template}
              className="!pt-0 absolute w-212 left-4 md:left-5 top-4 md:top-5 h-full !overflow-visible"
            />
          </div>
          <div
            className={cn(
              'w-8 h-[calc(100%-16px)] self-end grow -mr-6 shadow-md bg-base-white/60 rounded-tl-xl',
              { hidden: template.isSinglePage }
            )}
          ></div>
        </div>
      </div>
      <div className="relative flex items-center gap-2 mt-3 mb-1">
        <Emoji unified={template.content.icon} size={20} />
        <p className="text-base font-semibold text-gray-900 truncate">
          {template.name || 'Untitled'}
        </p>
      </div>
      <div className="flex items-center justify-between mt-2">
        <CreatedByRow template={template} />
        {!template.isDistribute && template.isOwner && isSettingsVisible && (
          <div>
            <TemplateItemSettingsDropdown
              handleEdit={handleTemplateEdit}
              handleDuplicate={handleTemplateDuplicate}
              template={template}
            />
          </div>
        )}
      </div>
    </div>
  );
};
