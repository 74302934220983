import {
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import classNames from 'classnames';
import { ChangeEvent, FC, useState } from 'react';
import { debounce } from 'lodash';
import { getFileSizeString } from '../../../../utils/files';
import { FilePreviewExt } from '@distribute/shared/generate-html';

export type FilePreviewNodeType = NodeViewProps['node'] & {
  attrs: {
    fileName: string;
    fileSize: number;
    fileUrl: string;
    fileType?: string;
  };
};

export type FilePreviewComponentProps = Partial<NodeViewProps> & {
  selected: boolean;
  node: FilePreviewNodeType;
  className?: string;
};

export const FilePreviewComponent = ({
  node,
  selected,
  className,
  updateAttributes,
}: FilePreviewComponentProps) => {
  const { attrs } = node;
  const { fileName, fileSize, fileType, fileUrl } = attrs;

  const wrapperClassName = classNames(
    '!border-0 overflow-hidden',
    selected && 'selected'
  );
  const [fileNameValue, setFileNameValue] = useState(fileName);

  const handleUpdateFileName = debounce((fileName: string) => {
    updateAttributes?.({ fileName });
  }, 500);

  const handleChangeFileName = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setFileNameValue(value);
    handleUpdateFileName(value);
  };

  return (
    <NodeViewWrapper className={wrapperClassName}>
      <div data-drag-handle draggable="true">
        <a
          draggable="false"
          href={fileUrl}
          target="_blank"
          rel="nofollow noreferrer"
          className={classNames(
            'flex items-center gap-3 w-full h-17 px-4.5 text-gray-800 border border-gray-300 rounded-lg node--filePreview hover:border-2 hover:border-primary-500 hover:pl-[17px]',
            className
          )}
          contentEditable={false}
        >
          <Icon
            glyph={IconMap.File05}
            width={40}
            className="text-primary-solid shrink-0"
          />
          <div className="grow-1 no-underline select-none text-inherit">
            <div className="text-gray-900 text-lg">
              {fileSize ? (
                fileName
              ) : (
                <input
                  className="text-gray-900 text-lg outline-none"
                  value={fileNameValue}
                  size={fileNameValue.length + 1}
                  onChange={handleChangeFileName}
                  onClick={(e) => e.preventDefault()}
                />
              )}
            </div>
            <div className="text-sm text-gray-700">
              {fileType ? fileType.toUpperCase() + ' ' : ''}
              {fileSize && getFileSizeString(fileSize)}
            </div>
          </div>
        </a>
      </div>
    </NodeViewWrapper>
  );
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    filePreview: {
      setFilePreview: ({
        fileName,
        fileSize,
        fileUrl,
        fileType,
        position,
      }: {
        fileName?: string;
        fileSize?: number;
        fileUrl: string;
        fileType?: string;
        position?: number;
      }) => ReturnType;
      removeFilePreview: () => ReturnType;
    };
  }
}

export const FilePreviewExtension = FilePreviewExt.extend({
  addCommands() {
    return {
      setFilePreview:
        ({ fileName, fileSize, fileUrl, fileType, position }) =>
        ({ commands }) => {
          if (position) {
            return commands.insertContentAt(position, {
              type: this.name,
              attrs: {
                fileName,
                fileSize,
                fileUrl,
                fileType,
              },
            });
          }
          return commands.insertContent({
            type: this.name,
            attrs: {
              fileName,
              fileSize,
              fileUrl,
              fileType,
            },
          });
        },
      removeFilePreview:
        () =>
        ({ commands }) => {
          return commands.deleteNode(this.name);
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(FilePreviewComponent as FC);
  },
});
