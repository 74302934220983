import React, { ReactNode, useState } from 'react';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

type Props = {
  value: string;
  isCollapsed?: boolean;
  children: ReactNode;
};

export const TemplatesNavigationGroupItem: React.FC<Props> = ({
  value,
  isCollapsed = false,
  children,
}) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState<boolean>(!isCollapsed);

  return (
    <>
      <button
        className="flex flex-row items-center flex-grow p-2 w-full rounded-md hover:bg-light-3 mt-2"
        onClick={() => setIsCategoryOpen(!isCategoryOpen)}
      >
        <div className="flex items-center">
          <div className="p-1 mr-3">
            <Icon
              glyph={isCategoryOpen ? IconMap.ChevronUp : IconMap.ArrowDown}
              className="text-gray-600"
              width={16}
            />
          </div>
          <span className="gap-2 text-xs font-bold text-gray-900">{value}</span>
        </div>
      </button>
      {isCategoryOpen && children}
    </>
  );
};
