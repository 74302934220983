import React from 'react';
import { EditorSidebarHeaderItem } from '.';
import {
  SidebarStepsEnum,
  TemplateSidebarTabsEnum,
} from '../../../../features/editor-sidebar';
import { FeatureFlag, FeatureFlags } from '../../../../entities/feature-flag';
import { TemplateEditorSidebarHeaderItem } from './TemplateEditorSidebarHeaderItem';
import { useTeamPermissions } from '../../../../features/teams';

type Props = {
  isTemplateMode?: boolean;
  isSupportMultiTabs?: boolean;
};

export const EditorSidebarHeader: React.FC<Props> = ({
  isTemplateMode,
  isSupportMultiTabs,
}) => {
  const { isGuest } = useTeamPermissions();
  return (
    <header className="h-12 bg-base-white border-b border-gray-200 flex items-end gap-4 pl-2 pr-4.5">
      {isTemplateMode && isSupportMultiTabs ? (
        <TemplateEditorSidebarHeaderItem
          text="Conversion tools"
          sidebarKey={TemplateSidebarTabsEnum.CONVERSIONS}
        />
      ) : null}
      {isTemplateMode ? (
        <TemplateEditorSidebarHeaderItem
          text="Template Settings"
          sidebarKey={TemplateSidebarTabsEnum.SETTINGS}
        />
      ) : (
        <>
          <EditorSidebarHeaderItem
            index={1}
            text="Add a CTA"
            sidebarKey={SidebarStepsEnum.CONVERSIONS}
          />
          <EditorSidebarHeaderItem
            index={2}
            text="Page Settings"
            sidebarKey={SidebarStepsEnum.SETTINGS}
          />
          {!isGuest && (
            <FeatureFlag feature={FeatureFlags.DISTRIBUTE_TAB}>
              <EditorSidebarHeaderItem
                index={3}
                text="Social Sharing"
                sidebarKey={SidebarStepsEnum.DISTRIBUTE}
              />
            </FeatureFlag>
          )}
        </>
      )}
    </header>
  );
};
