import React from 'react';
import { TemplatesSearch } from './TemplatesSearch';
import { TemplatesConfigEnum, templatesConfig, templatesModel } from '../../';
import { TemplatesNavigationItem } from './TemplatesNavigationItem';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Link, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { TEMPLATE_SUBMIT_LINK } from '../../../../shared/config';
import { TemplatesNavigationGroupItem } from './TemplatesNavigationGroupItem';
import {
  getCustomTemplates,
  getDistributeTemplatesByCategory,
} from '../../lib';
import { useTemplatesModalContext } from '../../';
import cn from 'classnames';

type TemplatesNavigationProps = {
  onTemplateTypeChange(isSinglePageTemplate: boolean): void;
};

enum TemplateTypeSwitcherEnum {
  OnePage = 'one-page',
  MultiTab = 'multi-tab',
}

export const TemplatesNavigation: React.FC<TemplatesNavigationProps> = ({
  onTemplateTypeChange,
}) => {
  const dispatch = useDispatch();
  const templatesModalContext = useTemplatesModalContext();
  const templates = useSelector(templatesModel.selectors.selectTemplates);

  const currentTemplatesFolder = useSelector(
    templatesModel.selectors.selectCurrentTemplatesFolder
  );

  const selectedTemplateType = templatesModalContext.isSinglePageTemplate
    ? TemplateTypeSwitcherEnum.OnePage
    : TemplateTypeSwitcherEnum.MultiTab;

  const handleTemplateFolderSelect = (folder: TemplatesConfigEnum) => {
    dispatch(templatesModel.actions.setCurrentTemplatesFolder(folder));
    dispatch(templatesModel.actions.setSearchQuery(''));
  };

  const handleTemplateTypeChange = (type: string) => {
    const isSinglePageTemplate = type === TemplateTypeSwitcherEnum.OnePage;
    onTemplateTypeChange(isSinglePageTemplate);
  };

  const templatesSwitcherItems = [
    {
      id: TemplateTypeSwitcherEnum.OnePage,
      title: 'One page',
      icon: IconMap.OnePage,
      isDisabled: !templatesModalContext.canSelectSingePageTemplate,
    },
    {
      id: TemplateTypeSwitcherEnum.MultiTab,
      title: 'Playbook',
      icon: IconMap.MultiTabPage,
      isDisabled: !templatesModalContext.canSelectMultiTabsTemplate,
    },
  ];

  return (
    <section className="shrink-0 w-[296px] flex flex-col justify-between">
      <div className="flex-grow pt-4 pl-4 overflow-x-hidden">
        <div className="h-full bg-gray-100 rounded-xl">
          <div className="flex flex-col h-full">
            <div className="p-4 overflow-y-auto border-b border-gray-200 min-h-[77px]">
              <TemplatesSearch />
            </div>
            <div className="p-4 border-b border-gray-200 min-h-[77px]">
              <div className="flex gap-1 p-1 border border-gray-200 bg-base-black-8 font-base rounded-xl w-fit-content">
                {templatesSwitcherItems.map((button) =>
                  button.isDisabled ? (
                    <Tooltip
                      key={button.id}
                      hideArrow
                      className="w-36 px-3 py-2"
                      trigger={
                        <span className="flex items-center gap-1.5 px-3 py-2 text-sm font-semibold rounded-md text-gray-500">
                          <Icon glyph={button.icon} />
                          {button.title}
                        </span>
                      }
                    >
                      <p className="font-semibold text-center">
                        Sorry, you can&apos;t use playbook templates on a single
                        tab.
                      </p>
                    </Tooltip>
                  ) : (
                    <button
                      key={button.id}
                      onClick={() => {
                        if (!button.isDisabled) {
                          handleTemplateTypeChange(button.id);
                        }
                      }}
                      className={cn(
                        'flex items-center gap-1.5 px-3 py-2 text-sm font-semibold rounded-md',
                        {
                          'hover:bg-base-white hover:shadow-sm hover:text-gray-700':
                            !button.isDisabled,
                          'text-gray-500': selectedTemplateType !== button.id,
                          'text-gray-700 bg-base-white shadow-sm':
                            !button.isDisabled &&
                            selectedTemplateType === button.id,
                        }
                      )}
                    >
                      <Icon glyph={button.icon} />
                      {button.title}
                    </button>
                  )
                )}
              </div>
            </div>
            <nav className="px-4 py-4 overflow-y-scroll">
              {templatesConfig.map((item) => {
                if (item.name) {
                  return (
                    <TemplatesNavigationItem
                      key={item.key}
                      itemKey={item.key}
                      value={item.name}
                      selected={item.key === currentTemplatesFolder}
                      onClick={() => handleTemplateFolderSelect(item.key)}
                      icon={item.icon}
                    />
                  );
                } else if (item.groupName) {
                  return (
                    <TemplatesNavigationGroupItem
                      value={item.groupName}
                      isCollapsed={item.isCollapsed}
                      key={item.groupName}
                    >
                      {item.items.map((groupItem) => (
                        <TemplatesNavigationItem
                          key={groupItem.key}
                          itemKey={groupItem.key}
                          value={groupItem.name}
                          selected={groupItem.key === currentTemplatesFolder}
                          onClick={() =>
                            handleTemplateFolderSelect(groupItem.key)
                          }
                          isEmpty={
                            !templates.find(
                              (el) => el.category === groupItem.key
                            )
                          }
                          amount={
                            groupItem.key === TemplatesConfigEnum.PERSONAL ||
                            groupItem.key === TemplatesConfigEnum.TEAM
                              ? getCustomTemplates(
                                  templates,
                                  groupItem.key,
                                  templatesModalContext.isSinglePageTemplate
                                )?.length
                              : getDistributeTemplatesByCategory(
                                  templates,
                                  groupItem.key,
                                  templatesModalContext.isSinglePageTemplate
                                )?.length
                          }
                        />
                      ))}
                    </TemplatesNavigationGroupItem>
                  );
                } else return null;
              })}
            </nav>
          </div>
        </div>
      </div>
      <div className="py-4 pl-4">
        <Link
          size="medium"
          className="mt-auto"
          target="_blank"
          href={TEMPLATE_SUBMIT_LINK}
        >
          <div className="p-0.25 rounded-lg relative bg-gradient-primary-100-to-light">
            <div className="absolute bottom-0 right-0">
              <img
                src="../../../../../assets/images/bg-circle-lines-part-primary.svg"
                alt="bg"
              />
            </div>
            <div className="px-6 py-4 rounded-lg bg-primary-50">
              <div className="relative z-10 mb-3 text-sm font-semibold text-gray-900">
                Can't find what you're looking for?
              </div>
              <div className="relative z-10 flex items-center gap-2">
                <Icon glyph={IconMap.Share06} width={20} />
                <span className="font-semibold text-md">
                  Suggest a template
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};
