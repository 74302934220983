import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import type { DocumentContentItem } from '@distribute/shared/types';
import { useScrollIntoView } from '@distribute/frontend/utils';
import { Icon } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';

type EditorTabsProps = {
  currentTab: DocumentContentItem;
  tabs: DocumentContentItem[];
  onChangeTab: (tab: DocumentContentItem) => void;
  isDisabled?: boolean;
};

export const EditorTabsPreview = ({
  tabs,
  currentTab,
  onChangeTab,
  isDisabled,
}: EditorTabsProps) => {
  const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(
    !!isDisabled || true
  );
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(!!isDisabled);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tabsWrapperRef = useRef<HTMLDivElement | null>(null);

  const containerElement = containerRef.current as HTMLDivElement;
  const { scrollToTop } = useScrollIntoView(containerRef, false);

  const handlePrevClick = () => {
    setScroll(-1);
  };
  const handleNextClick = () => {
    setScroll(1);
  };

  const handleTabClick = (tab: DocumentContentItem) => {
    onChangeTab(tab);
  };

  const getIsNextDisabled = () =>
    containerElement?.clientWidth + containerElement?.scrollLeft >=
    containerElement?.scrollWidth;

  const setScroll = (index: number) => {
    const containerElement = containerRef.current as HTMLDivElement;
    const tabsElement = tabsWrapperRef.current as HTMLDivElement;
    const currentScroll = containerElement.scrollLeft;
    const currentElementIndex = [
      ...Array.from(tabsElement?.children ?? []),
    ].findIndex(
      (element) =>
        element.getBoundingClientRect().right >=
        containerElement.getBoundingClientRect().left
    );
    const nextIndex = Math.max(
      0,
      Math.min(currentElementIndex + index, tabs.length - 1)
    );
    const currentElement = tabsWrapperRef.current?.children[
      nextIndex
    ] as HTMLElement;
    const scrollMax =
      currentElement.getBoundingClientRect().left -
      containerElement?.getBoundingClientRect().left;

    containerElement.scrollTo({
      top: 0,
      left: scrollMax + currentScroll,
      behavior: 'smooth',
    });

    const nextScroll = Math.min(
      scrollMax + currentScroll,
      containerElement.scrollWidth - containerElement.clientWidth
    );
    setIsNextDisabled(
      containerElement?.clientWidth + nextScroll >=
        containerElement?.scrollWidth - 1
    );
  };

  const isEqual =
    containerRef.current?.scrollWidth === containerRef.current?.clientWidth;

  useEffect(() => {
    if (!containerRef.current) return;

    containerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef.current]);

  const handleScroll = useCallback(
    debounce(() => {
      setIsPrevDisabled(containerElement?.scrollLeft === 0);
      setIsNextDisabled(getIsNextDisabled());
    }, 100),
    [containerElement]
  );

  useEffect(() => {
    if (!tabsWrapperRef.current || !containerRef.current) return;
    const containerElement = containerRef.current as HTMLDivElement;

    containerElement.addEventListener('scroll', handleScroll);

    return () => {
      containerElement.removeEventListener('scroll', handleScroll);
    };
  }, [tabsWrapperRef.current, containerRef.current, handleScroll]);

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop, currentTab.id]);

  return (
    <div className="relative border-b border-gray-300 mb-6">
      <div
        className={classNames(
          'mr-14 text-gray-500 font-semibold scrollbar-hide',
          {
            'overflow-hidden': isDisabled,
            'overflow-scroll': !isDisabled,
          }
        )}
        ref={containerRef}
      >
        <div className="flex gap-5 items-end" ref={tabsWrapperRef}>
          {tabs.map((tab) => (
            <button
              disabled={isDisabled}
              key={tab.id}
              className={classNames(
                'border-0 outline-0 pb-2 text-gray-500 whitespace-nowrap',
                {
                  'border-b-2 border-gray-900 text-gray-900 pb-1.5':
                    tab.id === currentTab.id,
                  'hover:text-gray-900': !isDisabled,
                }
              )}
              onClick={() => handleTabClick(tab)}
            >
              {tab.name ?? 'Untitled'}
            </button>
          ))}
        </div>
      </div>
      {!isEqual && (
        <div className="flex absolute right-0 top-0.5 text-gray-600">
          <button
            disabled={isPrevDisabled}
            className={classNames('border-0 outline-0', {
              'text-gray-400': isPrevDisabled,
            })}
            onClick={handlePrevClick}
          >
            <Icon className="rotate-180" glyph={IconMap.ChevronRight} />
          </button>
          <button
            disabled={isNextDisabled || isDisabled}
            className={classNames('border-0 outline-0', {
              'text-gray-400': isNextDisabled || isDisabled,
            })}
            onClick={handleNextClick}
          >
            <Icon glyph={IconMap.ChevronRight} />
          </button>
        </div>
      )}
    </div>
  );
};
