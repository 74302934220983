import { FC, useEffect } from 'react';
import '../../../../shared/styles/generate-html.css';
import {
  applyHtmlEnhancements,
  handleHtmlContentElementClick,
} from '@distribute/shared/generate-html';
import('@distribute/frontend/web-components');

type Props = {
  htmlContent: string;
};

export const EditorHtmlContent: FC<Props> = ({ htmlContent }) => {
  useEffect(() => {
    applyHtmlEnhancements();
  }, []);

  return (
    <div
      className="rich-text-content"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      onClick={handleHtmlContentElementClick}
    ></div>
  );
};
