import { addBreaks } from './addBreaks';
import { addTasklistData } from './addTasklistData';
import { addTimelineDueDate } from './addTimelineDueDate';
import { highlightCodeBlock } from './highlightCodeBlock';
import { setCorrectColumnWidth } from './setCorrectColumnWidth';

export const applyHtmlEnhancements = () => {
  highlightCodeBlock();
  setCorrectColumnWidth();
  addBreaks();
  addTimelineDueDate();
  addTasklistData();
};
