import { createContext, useContext } from 'react';

export type TemplatesModalContextValue = {
  isSinglePageTemplate: boolean;
  canSelectSingePageTemplate?: boolean;
  canSelectMultiTabsTemplate?: boolean;
};

export const TemplatesModalContext = createContext<TemplatesModalContextValue>({
  isSinglePageTemplate: false,
  canSelectSingePageTemplate: true,
  canSelectMultiTabsTemplate: true,
});

export const useTemplatesModalContext = () => {
  const context = useContext(TemplatesModalContext);
  if (!context) {
    throw new Error(
      'useTemplatesModalContext must be used within a TemplatesModalProvider'
    );
  }
  return context;
};
