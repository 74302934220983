import React, { useEffect } from 'react';
import { Modal, Button, Input, Select } from '../../../shared/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useController, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { foldersModel, getFolderPermissions } from '../../folders';
import { Folder } from '@distribute/shared/types';
import { pagesModel } from '../model';
import { authUserModel } from '../../../entities/auth-user';
import { useTeamPermissions } from '../../teams';
import { templatesModel } from '../../templates';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onChoose: (data: ChooseFolderModalForm) => void;
};

type ChooseFolderModalForm = {
  folderId: string;
  folderName?: string;
};

const validationSchema = object().shape({
  folderId: string().required('Folder id is required'),
  folderName: string().when('folderId', {
    is: 'newFolder',
    then: string().required('Folder Name is required'),
  }),
});

export const ChooseFolderModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onChoose,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(pagesModel.selectors.selectCreatePageIsLoading);

  const folders: Folder[] = useSelector(foldersModel.selectors.selectFolders);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ChooseFolderModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const teamPermissions = useTeamPermissions();

  const filteredFolders = folders.filter((folder) => {
    const folderPermissions = getFolderPermissions(
      currentUser,
      folder,
      teamPermissions
    );
    return folderPermissions.isCanCreatePages;
  });

  const foldersList = [
    ...filteredFolders,
    { title: 'New Folder...', id: 'newFolder' },
  ];

  const {
    field: { value, onChange },
  } = useController({
    control,
    defaultValue: foldersList[0].id,
    name: 'folderId',
    rules: { required: true },
  });

  const currentValue = foldersList.find((i) => i.id === value);

  const onModalClose = () => {
    reset();
    dispatch(templatesModel.actions.setCurrentSelectedTemplate(undefined));
    onClose();
  };

  const onSubmit = (data: ChooseFolderModalForm) => {
    onChoose(data);
    onClose();
  };

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Select Folder"
      actionButton={
        <Button
          onClick={handleSubmit(onSubmit)}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          loading={isLoading}
        >
          Next
        </Button>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          label="Folder"
          value={value}
          onChange={onChange}
          items={foldersList}
          currentValue={currentValue}
        />
        {value === 'newFolder' && (
          <div className="mt-3">
            <Input
              label="Folder Name"
              {...register('folderName')}
              isError={!!errors.folderName}
              messageText={errors.folderName?.message}
              type="text"
            />
          </div>
        )}
      </form>
    </Modal>
  );
};
