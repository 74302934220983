import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Dropdown, Icon, Tooltip } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import {
  foldersModel,
  useFolderPermissions,
} from '../../../../features/folders';
import {
  DeletePageModal,
  MoveToFolderModal,
  DuplicatePageToFolderModal,
  SaveAsTemplateModal,
  pagesModel,
  usePagePermissions,
} from '../../../../features/pages';
import { Folder, Page } from '@distribute/shared/types';
import { templatesModel } from '../../../../features/templates';

type Props = {
  listStyles?: string;
  align?: 'start' | 'end' | 'center';
};

export const PageSettingsDropdown: React.FC<Props> = ({
  listStyles,
  align = 'start',
}) => {
  const currentPage: Page = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const currentFolder: Folder = useSelector(
    foldersModel.selectors.selectCurrentFolderWithError
  );
  const saveAsTemplateIsLoading = useSelector(
    templatesModel.selectors.selectSavePageAsTemplateIsLoading
  );

  const folderPermissions = useFolderPermissions(currentFolder);
  const dispatch = useDispatch();
  const pagePermissions = usePagePermissions(currentPage);

  const isCanOnlyDuplicateToFolder =
    !folderPermissions.isFolderSharedWithUser &&
    pagePermissions.isCanEditDocumentContent;

  const onDuplicate = useCallback(() => {
    if (isCanOnlyDuplicateToFolder) {
      setIsDuplicatePageModalOpen(true);
      return;
    }

    dispatch(
      pagesModel.actions.duplicatePage({
        id: currentPage.id,
        folderId: currentFolder.id,
        source: 'editor',
      })
    );
  }, [currentFolder.id, currentPage.id, dispatch]);

  const pageItems = useMemo(
    () =>
      [
        {
          id: 'SaveAsTemplate',
          label: 'Save as playbook template',
          onClick: () => setIsSavePageAsTemplateModalOpen(true),
          iconName: IconMap.TemplatesPlus,
          iconWidth: 16,
          isShow: true,
        },
        {
          id: 'Duplicate',
          label: 'Duplicate',
          onClick: onDuplicate,
          iconName: IconMap.MenuCopy,
          isShow:
            folderPermissions.isCanDuplicatePages || isCanOnlyDuplicateToFolder,
        },
        {
          id: 'MoveToFolder',
          label: 'Move to Folder',
          onClick: () => {
            onMoveToFolderOpen();
          },
          iconName: IconMap.MoveToFolder,
          isShow: folderPermissions.isCanMovePagesToAnotherFolder,
        },
        {
          id: 'DeletePage',
          label: 'Delete Page',
          onClick: () => {
            onDeletePageOpen();
          },
          iconName: IconMap.Delete,
          isSeparatedFromTop: true,
          isShow: pagePermissions.isCanDelete,
        },
      ].filter((i) => i.isShow !== false),
    [
      onDuplicate,
      folderPermissions.isCanDuplicatePages,
      folderPermissions.isCanMovePagesToAnotherFolder,
      pagePermissions.isCanDelete,
    ]
  );

  const [isDeletePageModalOpen, setIsDeletePageModalOpen] =
    useState<boolean>(false);
  const [isMovePageToFolderModalOpen, setIsMovePageToFolderModalOpen] =
    useState<boolean>(false);
  const [isDuplicatePageModalOpen, setIsDuplicatePageModalOpen] =
    useState<boolean>(false);
  const [isSavePageAsTemplateModalOpen, setIsSavePageAsTemplateModalOpen] =
    useState<boolean>(false);

  const onDeletePageOpen = () => {
    setIsDeletePageModalOpen(true);
  };
  const onMoveToFolderOpen = () => {
    setIsMovePageToFolderModalOpen(true);
  };

  return (
    <>
      <Dropdown
        listStyles={cn('shadow-lg', listStyles)}
        triggerComponent={
          <Tooltip
            trigger={
              <DropdownMenu.Trigger className="rounded-lg flex items-center justify-center focus:outline-none text-gray-600 border border-gray-300 hover:bg-gray-50 h-9 w-9">
                <span className="flex items-center justify-center">
                  <Icon glyph={IconMap.DotsVertical} className="w-5 h-5" />
                </span>
              </DropdownMenu.Trigger>
            }
            sideOffset={4}
          >
            <p className="px-2 py-1 font-semibold text-xs">More actions</p>
          </Tooltip>
        }
        items={pageItems}
        align={align}
        isModalMode={false}
      />
      {currentPage && (
        <>
          <DeletePageModal
            isOpen={isDeletePageModalOpen}
            onClose={() => {
              setIsDeletePageModalOpen(false);
            }}
            page={currentPage}
          />
          <MoveToFolderModal
            isOpen={isMovePageToFolderModalOpen}
            onClose={() => {
              setIsMovePageToFolderModalOpen(false);
            }}
            page={currentPage}
          />
          <DuplicatePageToFolderModal
            isOpen={isDuplicatePageModalOpen}
            onClose={() => {
              setIsDuplicatePageModalOpen(false);
            }}
            page={currentPage}
          />
          <SaveAsTemplateModal
            name={currentPage.content.title}
            isSinglePageTemplate={false}
            isOpen={isSavePageAsTemplateModalOpen}
            onClose={() => setIsSavePageAsTemplateModalOpen(false)}
            page={currentPage}
            isLoading={saveAsTemplateIsLoading}
          />
        </>
      )}
    </>
  );
};
