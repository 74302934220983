import {
  TemplateBgColor,
  TemplateCreationPhase,
  TemplateSharingAccess,
} from '@distribute/shared/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { templatesModel } from '../../../../features/templates';
import { Input, Toggle } from '../../../../shared/ui';
import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { boolean, mixed, object, string } from 'yup';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { RadioButtonForBgColor } from './RadioButtonForBgColor';
import { RadioButtonForPermissions } from './RadioButtonForPermissions';

type TemplateSettingsModalForm = {
  name: string;
  description: string;
  sharingAccess: TemplateSharingAccess;
  bgColor: TemplateBgColor;
  isIncludeConversions: boolean;
};

const validationSchema = object().shape({
  name: string().required('Name is required'),
  description: string().optional(),
  sharingAccess: mixed<TemplateSharingAccess>().oneOf(
    Object.values(TemplateSharingAccess)
  ),
  bgColor: mixed<TemplateBgColor>().oneOf(Object.values(TemplateBgColor)),
  isIncludeConversions: boolean().required(),
});

export const TemplateSettingsForm: React.FC = () => {
  const dispatch = useDispatch();
  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<TemplateSettingsModalForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: currentTemplate?.name || '',
      description: currentTemplate?.description || '',
      sharingAccess:
        currentTemplate?.sharingAccess || TemplateSharingAccess.PERSONAL,
      bgColor: currentTemplate?.bgColor || TemplateBgColor.PRIMARY,
      isIncludeConversions: currentTemplate?.isIncludeConversionTools || false,
    },
  });

  const {
    field: { value: sharingAccessValue, onChange: setSharingAccess },
  } = useController({
    control,
    defaultValue: TemplateSharingAccess.PERSONAL,
    name: 'sharingAccess',
  });

  const onChangeSharingAccess = (value: TemplateSharingAccess) => {
    setSharingAccess(value);

    if (!currentTemplate) return;

    dispatch(
      templatesModel.actions.setCurrentTemplate({
        ...currentTemplate,
        sharingAccess: value,
      })
    );
    dispatch(templatesModel.actions.setIsTemplateSavedInEditor(false));
  };

  const {
    field: { value: bgColorValue, onChange: setBgColor },
  } = useController({
    control,
    defaultValue: TemplateBgColor.PRIMARY,
    name: 'bgColor',
  });

  const onChangeBgColor = (value: TemplateBgColor) => {
    if (!currentTemplate) {
      return;
    }
    setBgColor(value);
    dispatch(
      templatesModel.actions.setCurrentTemplate({
        ...currentTemplate,
        bgColor: value,
      })
    );
    dispatch(templatesModel.actions.setIsTemplateSavedInEditor(false));
  };

  const {
    field: {
      value: isIncludeConversionsValue,
      onChange: setIsIncludeConversions,
    },
  } = useController({
    control,
    defaultValue: false,
    name: 'isIncludeConversions',
  });

  const onChangeIsIncludeConversions = (value: boolean) => {
    setIsIncludeConversions(value);

    if (!currentTemplate) return;

    dispatch(
      templatesModel.actions.setCurrentTemplate({
        ...currentTemplate,
        isIncludeConversionTools: value,
      })
    );
    dispatch(templatesModel.actions.setIsTemplateSavedInEditor(false));
  };

  const onModalClose = () => {
    dispatch(editorSidebarModel.actions.setActiveConversionSettingsPanel(null));
  };

  const handleFormSubmit = (data: TemplateSettingsModalForm) => {
    if (!currentTemplate) return;

    dispatch(
      templatesModel.actions.setCurrentTemplate({
        ...currentTemplate,
        ...data,
        creationPhase: TemplateCreationPhase.READY_TO_USE,
      })
    );
    dispatch(templatesModel.actions.updateTemplateFromEditor());
    dispatch(templatesModel.actions.setIsTemplateSavedInEditor(true));
    onModalClose();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <Input
            autoComplete="off"
            label="Name *"
            {...register('name')}
            onChange={(e) => {
              if (!currentTemplate) return;

              dispatch(
                templatesModel.actions.setCurrentTemplate({
                  ...currentTemplate,
                  name: e.target.value,
                })
              );
              dispatch(
                templatesModel.actions.setIsTemplateSavedInEditor(false)
              );
            }}
            isError={!!errors.name}
            messageText={errors.name?.message}
            type="text"
            heightSize="md"
          />
          <Input
            autoComplete="off"
            label="Description"
            {...register('description')}
            isError={!!errors.description}
            messageText={errors.description?.message}
            onChange={(e) => {
              if (!currentTemplate) return;

              dispatch(
                templatesModel.actions.setCurrentTemplate({
                  ...currentTemplate,
                  description: e.target.value,
                })
              );
              dispatch(
                templatesModel.actions.setIsTemplateSavedInEditor(false)
              );
            }}
            type="text"
            isTextArea
            textAreaRows={3}
            textAreaMaxRows={3}
            maxCharacters={240}
            className="!h-23.5 max-h-23.5 min-h-23.5 flex-shrink-0 flex-grow-0"
            counterValue={currentTemplate?.description?.trim().length}
          />
        </div>
        <div className="hidden">
          <label className="block mb-3 font-semibold text-gray-700 text-md">
            Permissions
          </label>
          <div className="flex p-3 border border-gray-300 rounded-xl gap-x-3 bg-base-white">
            <div>
              <p className="mb-1 font-semibold text-gray-700 text-md">
                Include conversion tools
              </p>
              <p className="text-sm font-gray-600">
                Save the configuration settings of conversion tools in a
                template to be reused for any page generated from it.
              </p>
            </div>
            <Toggle
              checked={isIncludeConversionsValue}
              onChange={onChangeIsIncludeConversions}
            />
          </div>
        </div>
        <div>
          <label className="block mb-3 font-semibold text-gray-700 text-md">
            Who can use the template
          </label>
          <div className="flex items-center gap-4">
            {Object.values(TemplateSharingAccess).map((value) => (
              <RadioButtonForPermissions
                name="sharing-access"
                key={value}
                value={value}
                onChange={() => {
                  onChangeSharingAccess(value);
                }}
                defaultChecked={value === sharingAccessValue}
              />
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-3 font-semibold text-gray-700 text-md">
            Template card cover background
          </label>
          <div className="flex gap-3">
            {Object.values(TemplateBgColor).map((value) => (
              <RadioButtonForBgColor
                name="bg-color"
                colorValue={value}
                key={value}
                value={value}
                onChange={() => {
                  onChangeBgColor(value);
                }}
                defaultChecked={value === bgColorValue}
              />
            ))}
          </div>
        </div>
      </div>
    </form>
  );
};
