import React from 'react';
import { TemplateItem } from './TemplateItem';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

type Props = {
  templates: TemplateExtended[];
  searchQuery: string;
  onChoose(): void;
};

export const TemplatesList: React.FC<Props> = ({
  templates,
  searchQuery,
  onChoose,
}) => {
  return (
    <>
      {templates.map((template, i) => (
        <TemplateItem
          isSettingsVisible={false}
          template={template}
          key={template.id}
          onChoose={onChoose}
          searchQuery={searchQuery}
          index={i}
        />
      ))}
    </>
  );
};
