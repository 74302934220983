import React, { useCallback } from 'react';
import { Icon, Button } from '../../../../shared/ui';
import { IconMap } from '../../../../shared/sprite';
import { useDispatch, useSelector } from 'react-redux';
import { templatesModel } from '../../model';
import { TemplateSharingAccess } from '@distribute/shared/types';
import { SelectEventType, CreateTemplateButton } from './CreateTemplateButton';

type Props = {
  type: 'team' | 'personal' | 'recent' | 'search' | 'other';
};

export const NoTemplatesFound: React.FC<Props> = ({ type }) => {
  const dispatch = useDispatch();

  const notFoundMessageDictionary = {
    other: '',
    personal: 'Tailor templates to suit your specific needs.',
    team: 'Build templates that propel your team to success.',
    recent: 'Begin utilizing templates to fill this page out.',
    search:
      'We cannot find the template you need, but you can always create your own.',
  };

  const noFoundMessage = notFoundMessageDictionary[type];

  const isVisibleCreateBtn =
    type === 'team' || type === 'personal' || type === 'search';

  const handleCreateTemplate = useCallback(
    ({ hasMultiTabs }: SelectEventType) => {
      dispatch(
        templatesModel.actions.createBlankTemplate({
          sharingAccess:
            type === 'team'
              ? TemplateSharingAccess.TEAM
              : TemplateSharingAccess.PERSONAL,
          isSinglePage: !hasMultiTabs,
        })
      );
    },
    [dispatch, type]
  );

  const createBlankTemplateIsLoading = useSelector(
    templatesModel.selectors.selectCreateBlankTemplateIsLoading
  );

  return (
    <div className="relative flex flex-col items-center justify-center flex-grow w-full gap-2 overflow-hidden">
      <div className="z-10 flex flex-col items-center justify-center">
        <div className="border border-gray-200 rounded-[10px] p-3 shadow-xs relative w-12 h-12">
          <Icon
            glyph={
              type === 'recent' ? IconMap.TimeLoadingBack : IconMap.Templates
            }
            width={24}
            className="text-gray-700"
          />
          <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <Icon glyph={IconMap.BgSquare} />
            <div className="absolute top-0 w-full h-full bg-gradient-radial-transparent-to-white" />
          </div>
        </div>
        <div className="relative z-10 flex flex-col items-center justify-center mt-4">
          <p className="mb-1 font-semibold text-gray-900 text-md">
            No templates found
          </p>
          {noFoundMessage && (
            <p className="text-gray-600 text-sm font-normal max-w-[352px] text-center">
              {noFoundMessage}
            </p>
          )}
          {isVisibleCreateBtn && (
            <div className="absolute -bottom-16">
              <CreateTemplateButton
                onSelect={handleCreateTemplate}
                listStyles="w-45"
              >
                <Button
                  variant="text"
                  color="primary"
                  className="w-45 !h-11 text-md"
                  onClick={(e) => e.preventDefault()}
                  loading={createBlankTemplateIsLoading}
                >
                  <Icon
                    glyph={IconMap.TemplatesPlus}
                    width={20}
                    className="mr-1.5"
                  />
                  Create template
                </Button>
              </CreateTemplateButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
