import { ReactNodeViewRenderer } from '@tiptap/react';
import { ButtonNodeView } from './Button.renderer';
import { ButtonAttrs } from './types';
import './Button.css';
import { ButtonExt } from '@distribute/shared/generate-html';
import { FC } from 'react';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    button: {
      insertButton: (options?: { icon?: string }) => ReturnType;
    };
  }
}

export const Button = ButtonExt.extend({
  addCommands() {
    return {
      insertButton:
        (options?: Partial<ButtonAttrs>) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              ...options,
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ButtonNodeView as FC);
  },
});
