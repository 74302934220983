import { JSONContent, ReactNodeViewRenderer } from '@tiptap/react';
import { SnippetsModal } from './SnippetsModal';
import { MediaSnippetJSON, SnippetType } from '@distribute/shared/types';
import { SnippetBlockNodeView } from './SnippetBlock.renderer';
import { SnippetBlockExt } from '@distribute/shared/generate-html';
import { FC } from 'react';

export type SnippetAlign =
  | 'left'
  | 'center'
  | 'right'
  | 'float-left'
  | 'float-right';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    snippet: {
      insertSnippet: (options: {
        id?: string;
        type: SnippetType;
        content: JSONContent | MediaSnippetJSON;
        attrs?: {
          'data-media-width'?: number;
          'data-media-height'?: number;
        };
      }) => ReturnType;
      toggleSnippet: (options?: { id?: string }) => ReturnType;
      renderSnippetsModal: (onDestroy?: () => void) => ReturnType;
      deleteSnippet: () => ReturnType;
      setSnippetAlign: (align: SnippetAlign) => ReturnType;
    };
  }
}

export const SnippetBlock = SnippetBlockExt.extend({
  addCommands() {
    return {
      toggleSnippet:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
      insertSnippet:
        ({ id, attrs }) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              id: id || '',
              ...attrs,
            },
          });
        },

      setSnippetAlign:
        (align: SnippetAlign) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { 'data-align': align });
        },

      renderSnippetsModal:
        (onDestroy) =>
        ({ editor }) => {
          const { destroyComponent } =
            editor.extensionManager.commands.renderReactComponentWithTippy(
              SnippetsModal,
              {
                onClose: () => {
                  destroyComponent();
                  onDestroy?.();
                },
                offsetWidth: editor.view.dom.offsetWidth,
                onSelect: (
                  id: string,
                  type: SnippetType,
                  content: JSONContent | MediaSnippetJSON,
                  attrs?: {
                    'data-media-width'?: number;
                    'data-media-height'?: number;
                  }
                ) => {
                  destroyComponent();
                  onDestroy?.();

                  editor
                    .chain()
                    .insertSnippet({ id, type, content, attrs })
                    .focus()
                    .run();
                },
              }
            );

          return true;
        },
      deleteSnippet:
        () =>
        ({ chain, state }) =>
          chain()
            .setNodeSelection(state.selection.from)
            .deleteSelection()
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(SnippetBlockNodeView as FC);
  },
});
