import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Page } from '@distribute/shared/types';
import { EditorHeader } from './EditorHeader';
import classNames from 'classnames';
import { EditorSidebar } from '../../../../widgets/editor-sidebar';
import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { changeGleapBlockVisibility } from '../../../../entities/gleap';
import { useWindowWidth } from '@distribute/frontend/utils';
import { useUserAgentDevices } from '../../../../shared/hooks/useUserAgentDevices';
import { useCollaboration } from '../../../../entities/collaboration';

type Props = {
  isPreviewMode: boolean;
  children: React.ReactNode;
  currentPageOrTemplate: Page | TemplateExtended;
  isTemplateMode: boolean;
  isCanEdit: boolean;
  isSupportMultiTabs: boolean;
};

export const EditorWrapper: React.FC<Props> = ({
  isPreviewMode,
  children,
  currentPageOrTemplate,
  isTemplateMode,
  isCanEdit,
  isSupportMultiTabs,
}) => {
  const faviconHref = `https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${currentPageOrTemplate?.content.icon}.png`;

  useEffect(() => {
    changeGleapBlockVisibility(isPreviewMode ? 'hidden' : 'visible');
  }, [isPreviewMode]);

  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();
  const { isCollaborationEnabled, title } = useCollaboration();

  const pageTitle =
    isCollaborationEnabled && !isTemplateMode
      ? title
      : currentPageOrTemplate.content.title;

  return (
    <div
      className={classNames('flex flex-col relative', {
        'overflow-y-hidden h-screen': isPreviewMode,
        'min-h-screen': !isPreviewMode,
      })}
    >
      <Helmet
        titleTemplate={`${pageTitle ? pageTitle : 'Untitled'} - Distribute`}
      >
        {!isTemplateMode && (currentPageOrTemplate as Page).isBranded ? null : (
          <link
            id="favicon"
            rel="icon"
            href={faviconHref}
            type="image/x-icon"
          />
        )}
      </Helmet>

      <EditorHeader
        currentPageOrTemplate={currentPageOrTemplate}
        isTemplateMode={isTemplateMode}
      />
      {children}
      {!isMobile && !isTablet && isCanEdit && (
        <EditorSidebar
          isSupportMultiTabs={isSupportMultiTabs}
          currentPage={currentPageOrTemplate}
          isTemplateMode={isTemplateMode}
        />
      )}
    </div>
  );
};
