import { Page } from '@distribute/shared/types';
import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import {
  DeletePageAction,
  CreatePageAction,
  RenamePageAction,
  MovePageToFolderAction,
  ChangePageIconAction,
  CreatePageContentItemAction,
  DuplicatePageContentItemAction,
  DeletePageContentItemAction,
  UpdatePageContentItemAction,
  ChangePageContentAction,
  ChangeBrandColorAction,
  ChangePageStyle,
  DuplicatePageAction,
  UpdateTeamMemberPermissionAction,
  CreateUserPermissionAction,
  UpdateUserPermissionAction,
  DeleteUserPermissionAction,
  EditorPreviewModeEnum,
  UpdatePageAction,
  ChangePageCoverImageAction,
  ChangePageBrandLogoAction,
  SetActionPreviewAction,
  ChangePublicStatusAction,
  ChangePagePublicAccessAction,
  ResendUserPermissionInvitationAction,
  GetPagePublicInfoAction,
  RequestToEditPageAction,
  BulkUpdatePageContentItemAction,
  SetCurrentContentItemAction,
  SetContentNavigationAction,
} from './types';
import { globalActions } from '../../../app/model/actions';
import { CreateWithAIRequestType } from '@distribute/shared/api-types/create-with-ai';
import type { DocumentContentItem } from '@distribute/shared/types';

type PagesStatus = 'never' | 'pending' | 'error' | 'success';

type CurrentPageStatus = PagesStatus;

type State = {
  pages: Page[];
  publishedPagesCount: number;
  currentPage: Page | undefined;
  pagesStatus: PagesStatus;
  currentPageStatus: CurrentPageStatus;
  currentPageIsError: boolean;
  currentContentId: number | undefined;
  openedContentItemId: number | undefined;
  createPageIsLoading: boolean;
  deletePageIsLoading: boolean;
  movePageToFolderIsLoading: boolean;
  changePageIconIsLoading: boolean;
  isPageSettingsModalOpen: boolean;
  isEditorPreview: boolean;
  changePageContentIsLoading: boolean;
  pageEditorDataIsLoading: boolean;
  createUserPermissionIsLoading: boolean;
  isEditorSidebarOpen: boolean;
  editorPreviewMode: EditorPreviewModeEnum;
  isSlashMenuOpen: boolean;
  slashMenuPositionTop: number;
  slashMenuPositionLeft: number;
  isPageCoverImageMenuOpen: boolean;
  isPublishSettingsDropdownOpen: boolean;
  isBrandLogoDeleting: boolean;
  currentActionPreview: SetActionPreviewAction | null;
  generatePagePromptData: CreateWithAIRequestType | null;
  contentNavigation: {
    tab: DocumentContentItem;
    direction: 'back' | 'forward';
  } | null;
};

const initialState: State = {
  pages: [],
  publishedPagesCount: 0,
  currentPage: undefined,
  currentPageStatus: 'never',
  currentPageIsError: false,
  currentContentId: undefined,
  openedContentItemId: undefined,
  pagesStatus: 'never',
  createPageIsLoading: false,
  deletePageIsLoading: false,
  movePageToFolderIsLoading: false,
  isPageSettingsModalOpen: false,
  changePageIconIsLoading: false,
  changePageContentIsLoading: false,
  isEditorPreview: false,
  pageEditorDataIsLoading: false,
  createUserPermissionIsLoading: false,
  isEditorSidebarOpen: false,
  editorPreviewMode: EditorPreviewModeEnum.DESKTOP,
  isSlashMenuOpen: false,
  slashMenuPositionTop: 0,
  slashMenuPositionLeft: 0,
  isPageCoverImageMenuOpen: false,
  isPublishSettingsDropdownOpen: false,
  isBrandLogoDeleting: false,
  currentActionPreview: null,
  generatePagePromptData: null,
  contentNavigation: null,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setPages: (state, { payload: pages }: PayloadAction<Page[]>) => ({
      ...state,
      pages,
      publishedPagesCount: pages.reduce(
        (count, page) => count + Number(page.published),
        0
      ),
    }),
    setCreatePageIsLoading: (
      state,
      { payload: createPageIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      createPageIsLoading,
    }),
    setDeletePageIsLoading: (
      state,
      { payload: deletePageIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      deletePageIsLoading,
    }),
    setMovePageToFolderIsLoading: (
      state,
      { payload: movePageToFolderIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      movePageToFolderIsLoading,
    }),
    setSavePageAsTemplateIsLoading: (
      state,
      { payload: savePageAsTemplateIsLoading }
    ) => ({
      ...state,
      savePageAsTemplateIsLoading,
    }),
    setChangePageIconIsLoading: (
      state,
      { payload: changePageIconIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      changePageIconIsLoading,
    }),
    setChangePageContentIsLoading: (
      state,
      { payload: changePageContentIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      changePageContentIsLoading,
    }),
    setCurrentPage: (
      state,
      { payload: currentPage }: PayloadAction<Page | undefined>
    ) => ({
      ...state,
      currentPage,
    }),
    setPagesStatus: (
      state,
      { payload: pagesStatus }: PayloadAction<PagesStatus>
    ) => ({
      ...state,
      pagesStatus,
    }),
    setCurrentPageStatus: (
      state,
      { payload: currentPageStatus }: PayloadAction<CurrentPageStatus>
    ) => ({
      ...state,
      currentPageStatus,
    }),
    setCurrentPageIsError: (
      state,
      { payload: currentPageIsError }: PayloadAction<boolean>
    ) => ({
      ...state,
      currentPageIsError,
    }),
    setCurrentContentId: (
      state,
      { payload: currentContentId }: PayloadAction<number | undefined>
    ) => ({
      ...state,
      currentContentId,
      openedContentItemId: undefined,
    }),
    setOpenedContentItemId: (
      state,
      { payload: openedContentItemId }: PayloadAction<number | undefined>
    ) => ({
      ...state,
      openedContentItemId,
    }),
    setIsPageSettingsModalOpen: (
      state,
      { payload: isPageSettingsModalOpen }: PayloadAction<boolean>
    ) => ({
      ...state,
      isPageSettingsModalOpen,
    }),
    setIsEditorPreview: (
      state,
      { payload: isEditorPreview }: PayloadAction<boolean>
    ) => ({
      ...state,
      isEditorPreview,
    }),
    setPageEditorDataIsLoading: (
      state,
      { payload: pageEditorDataIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      pageEditorDataIsLoading,
    }),
    setCreateUserPermissionIsLoading: (
      state,
      { payload: createUserPermissionIsLoading }: PayloadAction<boolean>
    ) => ({
      ...state,
      createUserPermissionIsLoading,
    }),
    setEditorSidebarOpen: (
      state,
      { payload: isEditorSidebarOpen }: PayloadAction<boolean>
    ) => ({ ...state, isEditorSidebarOpen }),
    setEditorPreviewMode: (
      state,
      { payload: editorPreviewMode }: PayloadAction<EditorPreviewModeEnum>
    ) => ({
      ...state,
      editorPreviewMode,
    }),
    setSlashMenuOpen: (
      state,
      { payload: isSlashMenuOpen }: PayloadAction<boolean>
    ) => ({ ...state, isSlashMenuOpen }),
    setSlashMenuPositionTop: (
      state,
      { payload: slashMenuPositionTop }: PayloadAction<number>
    ) => ({ ...state, slashMenuPositionTop }),
    setSlashMenuPositionLeft: (
      state,
      { payload: slashMenuPositionLeft }: PayloadAction<number>
    ) => ({ ...state, slashMenuPositionLeft }),
    setIsPageCoverImageMenuOpen: (
      state,
      { payload: isPageCoverImageMenuOpen }: PayloadAction<boolean>
    ) => ({ ...state, isPageCoverImageMenuOpen }),
    setIsPublishSettingsDropdownOpen: (
      state,
      { payload: isPublishSettingsDropdownOpen }: PayloadAction<boolean>
    ) => ({ ...state, isPublishSettingsDropdownOpen }),
    setIsBrandLogoDeleting: (
      state,
      { payload: isBrandLogoDeleting }: PayloadAction<boolean>
    ) => ({ ...state, isBrandLogoDeleting }),
    setActionPreview: (
      state,
      { payload: actionPreview }: PayloadAction<SetActionPreviewAction | null>
    ) => ({ ...state, currentActionPreview: actionPreview }),
    setGeneratePagePromptData: (
      state,
      {
        payload: generatePagePromptData,
      }: PayloadAction<CreateWithAIRequestType | null>
    ) => ({ ...state, generatePagePromptData }),
    toggleContentNavigation: (
      state,
      { payload: contentNavigation }: PayloadAction<SetContentNavigationAction>
    ) => ({ ...state, contentNavigation }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  createPage: createAction<CreatePageAction>('pages/createPage'),
  deletePage: createAction<DeletePageAction>('pages/deletePage'),
  renamePage: createAction<RenamePageAction>('pages/renamePage'),
  changePageIcon: createAction<ChangePageIconAction>('pages/changePageIcon'),
  changeCoverImageUrl: createAction<ChangePageCoverImageAction>(
    'pages/changeCoverImageUrl'
  ),
  createPageContentItem: createAction<CreatePageContentItemAction>(
    'pages/createPageContentItem'
  ),
  duplicatePageContentItem: createAction<DuplicatePageContentItemAction>(
    'pages/duplicatePageContentItem'
  ),
  deletePageContentItem: createAction<DeletePageContentItemAction>(
    'pages/deletePageContentItem'
  ),
  updatePageContentItem: createAction<UpdatePageContentItemAction>(
    'pages/updatePageContentItem'
  ),
  bulkUpdatePageContentItems: createAction<BulkUpdatePageContentItemAction>(
    'pages/bulkUpdatePageContentItems'
  ),
  changePageContent: createAction<ChangePageContentAction>(
    'pages/changePageContent'
  ),
  changeBrandColor: createAction<ChangeBrandColorAction>(
    'pages/changeBrandColor'
  ),
  changeBrandLogo: createAction<ChangePageBrandLogoAction>(
    'pages/changeBrandLogo'
  ),
  deletePageBrandLogo: createAction('pages/deletePageBrandLogo'),
  deletePageCoverImage: createAction('pages/deletePageCoverImage'),
  changePageStyle: createAction<ChangePageStyle>('pages/changePageStyle'),
  movePageToFolder: createAction<MovePageToFolderAction>(
    'pages/movePageToFolder'
  ),
  updatePage: createAction<UpdatePageAction>('pages/updatePage'),
  updatePageFromTemplate: createAction<UpdatePageAction>(
    'pages/updatePageFromTemplate'
  ),
  updatePages: createAction<Page | undefined>('pages/updatePages'),
  duplicatePage: createAction<DuplicatePageAction>('pages/duplicatePage'),
  updateTeamMemberPermission: createAction<UpdateTeamMemberPermissionAction>(
    'pages/updateTeamMemberPermission'
  ),
  createUserPermission: createAction<CreateUserPermissionAction>(
    'pages/createUserPermission'
  ),
  updateUserPermission: createAction<UpdateUserPermissionAction>(
    'pages/updateUserPermission'
  ),
  deleteUserPermission: createAction<DeleteUserPermissionAction>(
    'pages/deleteUserPermission'
  ),
  resendUserPermissionInvitation:
    createAction<ResendUserPermissionInvitationAction>(
      'pages/resendUserPermissionInvitation'
    ),
  setActionPreview: createAction<SetActionPreviewAction | null>(
    'pages/setActionPreview'
  ),
  changePublicStatus: createAction<ChangePublicStatusAction>(
    'pages/changePublicStatus'
  ),
  changePagePublicAccess: createAction<ChangePagePublicAccessAction>(
    'pages/updatePagePublicAccess'
  ),
  getPagePublicInfo: createAction<GetPagePublicInfoAction>(
    'pages/getPagePublicInfo'
  ),
  requestToEditPage: createAction<RequestToEditPageAction>(
    'pages/requestToEditPage'
  ),
  setCurrentContentItem: createAction<SetCurrentContentItemAction>(
    'pages/setCurrentContentItem'
  ),
  setContentNavigation: createAction<SetContentNavigationAction>(
    'pages/setContentNavigation'
  ),
};
