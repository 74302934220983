import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { templatesModel } from '..';
import { call, select } from 'redux-saga/effects';
import { TemplateCreationPhase } from '@distribute/shared/types';
import { updateTemplateData } from '../../lib/updateTemplateData';

export function* updateTemplateFromEditor() {
  const template: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const isShowSuccessNotification =
    template.creationPhase !== TemplateCreationPhase.DRAFT;
  const { content } = template;
  const { contentItems } = content;

  yield call(updateTemplateData, {
    template: {
      ...template,
      content: {
        ...content,
        contentItems: contentItems.map((contentItem) => ({
          ...contentItem,
          id: contentItem.id < 0 ? undefined : contentItem.id,
        })),
      },
    } as TemplateExtended,
    isShowSuccessNotification,
  });
}
