import { actions } from '../reducer';
import { put } from 'redux-saga/effects';

import { pagesModel } from '../index';
import { logger } from '../../../../shared/lib';
import { createNotification, snackbarModel } from '../../../snackbar';
import { pagesApi } from '../../../../shared/api/';
import { Page } from '@distribute/shared/types';
import { captureMessage } from '@sentry/react';

type DataType =
  | ReturnType<typeof pagesModel.actions.createPageContentItem>
  | ReturnType<typeof pagesModel.actions.duplicatePageContentItem>
  | ReturnType<typeof pagesModel.actions.deletePageContentItem>
  | ReturnType<typeof pagesModel.actions.updatePageContentItem>
  | ReturnType<typeof pagesModel.actions.bulkUpdatePageContentItems>;

type PayloadType = DataType['payload'];
type ActionType = {
  api: (payload: PayloadType) => Promise<Page>;
  errorMessage: string;
};

export function* changePageContentItem({
  payload: { callback, ...rest },
  type,
}: DataType) {
  // TODO: define why failed and fix
  //eslint-disable-next-line
  // @ts-ignore
  const listActions: Record<string, ActionType> = {
    [pagesModel.actions.createPageContentItem.type]: {
      api: pagesApi.createPageContentItem,
      errorMessage: 'Failed to create page content',
    },
    [pagesModel.actions.updatePageContentItem.type]: {
      api: pagesApi.updatePageContentItem,
      errorMessage: 'Failed to update page content',
    },
    [pagesModel.actions.bulkUpdatePageContentItems.type]: {
      api: pagesApi.bulkUpdatePageContentItems,
      errorMessage: 'Failed to update page content',
    },
    [pagesModel.actions.duplicatePageContentItem.type]: {
      api: pagesApi.duplicatePageContentItem,
      errorMessage: 'Failed to create duplicate page content',
    },
    [pagesModel.actions.deletePageContentItem.type]: {
      api: pagesApi.deletePageContentItem,
      errorMessage: 'Failed to delete page content',
    },
  };

  const action = listActions[type];

  if (!action) {
    captureMessage('Not supported change page content action', {
      //eslint-disable-next-line
      //@ts-ignore
      level: 'error',
      extra: {
        actionType: type,
      },
    });
    throw new Error('Not supported change page content action');
  }

  try {
    yield put(actions.setChangePageContentIsLoading(true));
    yield put(actions.setCurrentPageStatus('pending'));

    const page: Page = yield action.api(rest);

    if (!page) {
      throw new Error('updatedPage is undefined');
    }
    yield put(pagesModel.actions.setCurrentPage(page));
    yield put(actions.setCurrentPageStatus('success'));
    yield put(actions.setCurrentPageIsError(false));

    if (type === pagesModel.actions.createPageContentItem.type) {
      const contentItems = page.content.contentItems;
      const lastContentItem = contentItems[contentItems.length - 1];
      yield put(actions.setCurrentContentId(lastContentItem.id));
      yield put(actions.setOpenedContentItemId(lastContentItem.id));
      callback?.(null, lastContentItem);
    }

    if (type === pagesModel.actions.duplicatePageContentItem.type) {
      const sortedContentItems = [...page.content.contentItems].sort(
        (a, b) => a.id - b.id
      );
      const lastContentItem = sortedContentItems[sortedContentItems.length - 1];
      callback?.(null, lastContentItem);
    }

    callback?.();
  } catch (e: unknown) {
    logger.error(e);
    yield put(actions.setCurrentPageStatus('error'));
    yield put(actions.setCurrentPageIsError(true));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', action.errorMessage)
      )
    );
    callback?.(e);
  } finally {
    yield put(actions.setChangePageContentIsLoading(false));
  }
}
