import {
  ReactNodeViewRenderer,
  Node,
  NodeViewWrapper,
  NodeViewContent,
  Editor,
  nodePasteRule,
} from '@tiptap/react';
import { useDispatch } from 'react-redux';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { FC, useCallback, useState } from 'react';
import cn from 'classnames';
import { CustomLinkHoveringMenu } from './CustomLinkHoveringMenu';
import { CustomLinkEditMenu } from './CustomLinkEditMenu';
import {
  CALENDLY_URL_REGEX,
  GOOGLE_URL_REGEX,
  LINK_VERIFICATION_REGEX,
  LOOM_URL_REGEX,
} from '../../../../shared/constants';
import { CustomLinkExt } from '@distribute/shared/generate-html';

export type LinkNodeType = {
  attrs: {
    href: string;
    text: string;
  };
};

export type LinkComponentProps = {
  selected: boolean;
  node: LinkNodeType;
  className?: string;
  editor: Editor;
  getPos(): number;
  updateAttributes(attributes: { [key: string]: string }): void;
  extension: Node;
};

const LinkComponent = ({
  node,
  editor,
  updateAttributes,
  getPos,
  extension,
}: LinkComponentProps) => {
  const dispatch = useDispatch();
  const [isEditLinkMenuOpen, setEditLinkMenuOpen] = useState(!node.attrs.href);

  const handleCopy = () => {
    navigator.clipboard.writeText(node.attrs.href);
    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'success',
          'The link has been copied to your clipboard'
        )
      )
    );
  };

  const handleRemoveLink = useCallback(
    (pos: number) => {
      editor.commands.unsetCustomLink(pos ?? getPos());
    },
    [editor.commands, getPos]
  );

  const [hrefValue, setHrefValue] = useState<string>(node.attrs.href);
  const [isValidationError, setValidationError] = useState(false);

  const handleEditMenuOpen = () => {
    setEditLinkMenuOpen(true);
  };

  const handleEditMenuClose = () => {
    setEditLinkMenuOpen(false);
  };

  const handleLinkEditSubmit = () => {
    const isLinkValid = LINK_VERIFICATION_REGEX.test(hrefValue);
    if (!isLinkValid) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
      updateAttributes({ href: hrefValue });
      setEditLinkMenuOpen(false);
    }
  };

  const handleOpenUrl = () => {
    window
      .open(
        node.attrs.href.match(/^https?:/)
          ? node.attrs.href
          : `//${node.attrs.href}`,
        '_blank'
      )
      ?.focus();
  };

  return (
    <NodeViewWrapper className="inline !border-0 relative group">
      <NodeViewContent
        className={cn('inline cursor-pointer', {
          'underline text-primary-solid': node.attrs.href,
          'bg-indigo-200 rounded shadow-indigo': isEditLinkMenuOpen,
        })}
        onClick={handleOpenUrl}
      >
        {extension?.options?.isLinkOnly
          ? node.attrs.href.trim().length
            ? node.attrs.href
            : node.attrs.text
          : node.attrs.text}
      </NodeViewContent>
      {editor.isEditable && !isEditLinkMenuOpen && (
        <CustomLinkHoveringMenu
          node={node}
          handleCopy={handleCopy}
          handleEditMenuOpen={handleEditMenuOpen}
          handleRemoveLink={handleRemoveLink}
          editor={editor}
        />
      )}
      {isEditLinkMenuOpen && (
        <CustomLinkEditMenu
          node={node}
          setHrefValue={setHrefValue}
          hrefValue={hrefValue}
          handleLinkEditSubmit={handleLinkEditSubmit}
          handleRemoveLink={handleRemoveLink}
          handleEditMenuClose={handleEditMenuClose}
          editor={editor}
          linkPos={getPos()}
          isError={isValidationError}
        />
      )}
    </NodeViewWrapper>
  );
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customLink: {
      setCustomLink: () => ReturnType;
      unsetCustomLink: (pos?: number) => ReturnType;
      toggleCustomLink: () => ReturnType;
    };
  }
}

export const CustomLink = CustomLinkExt.extend({
  addPasteRules() {
    return [
      nodePasteRule({
        find: LOOM_URL_REGEX,
        type: this.type,
        getAttributes: (match) => {
          const selection = this.editor.view.state.selection;
          setTimeout(() => {
            this.editor
              .chain()
              .focus()
              .setNodeSelection(selection.from)
              .renderLoomVideoEmbedContent({
                link: match.input,
              })
              .run();
          }, 0);

          return {
            href: match.input,
            text: match.input,
          };
        },
      }),
      nodePasteRule({
        find: GOOGLE_URL_REGEX,
        type: this.type,
        getAttributes: (match) => {
          const selection = this.editor.view.state.selection;
          setTimeout(() => {
            this.editor
              .chain()
              .focus()
              .setNodeSelection(selection.from)
              .renderGoogleDocsEmbedContent({
                link: match.input,
              })
              .run();
          }, 0);

          return {
            href: match.input,
            text: match.input,
          };
        },
      }),
      nodePasteRule({
        find: CALENDLY_URL_REGEX,
        type: this.type,
        getAttributes: (match) => {
          const selection = this.editor.view.state.selection;
          setTimeout(() => {
            this.editor
              .chain()
              .focus()
              .setNodeSelection(selection.from)
              .renderIFrameEmbedContent({
                link: match.input,
              })
              .run();
          }, 0);

          return {
            href: match.input,
            text: match.input,
          };
        },
      }),
    ];
  },

  addCommands() {
    return {
      setCustomLink:
        () =>
        ({ commands, view }) => {
          const { from, to } = view.state.tr.selection;
          const text = view.state.doc.textBetween(from, to, '');

          return commands.insertContent({
            type: this.name,
            attrs: {
              text,
              href: '',
            },
          });
        },
      unsetCustomLink:
        (pos) =>
        ({ view }) => {
          const { from, to } = view.state.tr.selection;
          const node = view.state.doc.nodeAt(pos ?? from);
          if (!node) return true;

          setTimeout(() => {
            view.dispatch(
              view.state.tr.delete(pos ?? from, pos ? pos + node.nodeSize : to)
            );
            if (node?.attrs.text) {
              view.dispatch(
                view.state.tr.insertText(`${node.attrs.text}`, pos ?? from)
              );
            }
          }, 0);

          return true;
        },
      toggleCustomLink:
        () =>
        ({ view, commands }) => {
          const { from } = view.state.tr.selection;
          const node = view.state.doc.nodeAt(from);

          if (node?.type.name === 'customLink') {
            return commands.unsetCustomLink();
          }
          return commands.setCustomLink();
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(LinkComponent as FC, this.options);
  },

  addKeyboardShortcuts() {
    return {
      'Mod-k': () => this.editor.commands.toggleCustomLink(),
    };
  },
});
