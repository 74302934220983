import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { FilterByTypeState } from '../config';

export const getFilteredTemplatesByType = (
  templates: TemplateExtended[],
  currentFilterByTypeState: string
) => {
  if (currentFilterByTypeState === FilterByTypeState.ONE_PAGE) {
    return templates.filter(({ isSinglePage }) => isSinglePage);
  }

  if (currentFilterByTypeState === FilterByTypeState.MULTI_TABS) {
    return templates.filter(({ isSinglePage }) => !isSinglePage);
  }

  return templates;
};
