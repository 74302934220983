export const noTemplatesMessageDictionary = {
  personal: 'Tailor templates to suit your specific needs.',
  team: 'Build templates that propel your team to success.',
  drafts: 'Create templates to streamline your workflow.',
  search:
    'We cannot find the template you need, but you can always create your own.',
};

export enum FilterByTypeState {
  ALL_TEMPLATES = 'all-templates',
  ONE_PAGE = 'one-page',
  MULTI_TABS = 'multi-tabs',
}

export enum SortState {
  LAST_CREATED = 'last-created',
  MOST_POPULAR = 'most-popular',
  NAME = 'name',
}
