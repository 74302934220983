import {
  TemplateBgColor,
  TemplateCreationPhase,
  TemplateSharingAccess,
} from '@distribute/shared/types';
import { Button, Icon, Input, Modal, Toggle } from '../../../../shared/ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string, boolean, mixed } from 'yup';
import { templatesModel } from '../../model';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconMap } from '../../../../shared/sprite';
import { RadioButtonForPermissions } from '../../../../widgets/editor-sidebar/ui/components/RadioButtonForPermissions';
import { RadioButtonForBgColor } from '../../../..//widgets/editor-sidebar/ui/components/RadioButtonForBgColor';
import { TemplateExtended } from '@distribute/shared/api-types/templates';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  template: TemplateExtended;
};

type TemplateSettingsModalForm = {
  name: string;
  description: string;
  sharingAccess: TemplateSharingAccess;
  bgColor: TemplateBgColor;
  isIncludeConversions: boolean;
};

const validationSchema = object().shape({
  name: string().required('Name is required'),
  description: string().optional(),
  sharingAccess: mixed<TemplateSharingAccess>().oneOf(
    Object.values(TemplateSharingAccess)
  ),
  bgColor: mixed<TemplateBgColor>().oneOf(Object.values(TemplateBgColor)),
  isIncludeConversions: boolean(),
});

export const TemplateSettingsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  template,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    templatesModel.selectors.selectTemplateIsUpdating
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm<TemplateSettingsModalForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: template?.name || '',
      description: template?.description || '',
      sharingAccess: template?.sharingAccess || TemplateSharingAccess.PERSONAL,
      bgColor: template?.bgColor || TemplateBgColor.PRIMARY,
      isIncludeConversions: template?.isIncludeConversionTools || false,
    },
  });
  const {
    field: { value: sharingAccessValue, onChange: setSharingAccess },
  } = useController({
    control,
    defaultValue: TemplateSharingAccess.PERSONAL,
    name: 'sharingAccess',
  });

  const {
    field: { value: bgColorValue, onChange: setBgColor },
  } = useController({
    control,
    defaultValue: TemplateBgColor.PRIMARY,
    name: 'bgColor',
  });

  const {
    field: {
      value: isIncludeConversionsValue,
      onChange: setIsIncludeConversions,
    },
  } = useController({
    control,
    defaultValue: false,
    name: 'isIncludeConversions',
  });

  const onChangeIsIncludeConversions = (value: boolean) => {
    setIsIncludeConversions(value);
  };

  const [isReadyLoading, setIsReadyLoading] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);

  const onModalClose = () => {
    setIsReadyLoading(false);
    setIsDraftLoading(false);
    reset();
    onClose();
  };

  const handleSaveTemplateAsDraft = (data: TemplateSettingsModalForm) => {
    setIsDraftLoading(true);
    dispatch(
      templatesModel.actions.updateTemplateFromSettings({
        template: {
          ...template,
          name: data.name,
          description: data.description,
          sharingAccess: data.sharingAccess,
          bgColor: data.bgColor,
          creationPhase: TemplateCreationPhase.DRAFT,
          isIncludeConversionTools: data.isIncludeConversions,
        },
        callback: onModalClose,
      })
    );
  };

  const handleSaveTemplateAsReady = (data: TemplateSettingsModalForm) => {
    setIsReadyLoading(true);
    dispatch(
      templatesModel.actions.updateTemplateFromSettings({
        template: {
          ...template,
          name: data.name,
          description: data.description,
          sharingAccess: data.sharingAccess,
          bgColor: data.bgColor,
          creationPhase: TemplateCreationPhase.READY_TO_USE,
          isIncludeConversionTools: data.isIncludeConversions,
        },
        callback: onModalClose,
      })
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      isActionButtonsAlignEnd={true}
      className="relative overflow-hidden !p-0 !pb-23.5 w-120 max-h-180"
      isShowCancelButton={false}
    >
      <div className="p-6 overflow-scroll max-h-157">
        <img
          src="../../../assets/images/bg-save-template-rect-part.svg"
          alt="background"
          className="absolute top-0 left-0"
        />
        <div className="relative mb-5 z-1">
          <div className="flex items-center justify-between mb-4">
            <div className="p-3 bg-gray-100 rounded-full">
              <Icon glyph={IconMap.Templates} className="text-gray-500" />
            </div>
            <Icon
              glyph={IconMap.XClose}
              className="text-gray-500 cursor-pointer shrink-0"
              width={24}
              onClick={onModalClose}
            />
          </div>
          <p className="mb-1 font-medium font-display text-display-xs">
            Template Settings
          </p>
        </div>
        <form onSubmit={() => false}>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <Input
                autoComplete="off"
                label="Name"
                {...register('name')}
                isError={!!errors.name}
                messageText={errors.name?.message}
                type="text"
              />
              <Input
                autoComplete="off"
                label="Description"
                {...register('description')}
                isError={!!errors.description}
                messageText={errors.description?.message}
                type="text"
                isTextArea
                textAreaRows={3}
                textAreaMaxRows={3}
                maxCharacters={240}
                className="!h-23.5 max-h-23.5 min-h-23.5 flex-shrink-0 flex-grow-0"
                counterValue={watch('description')?.trim().length}
              />
            </div>
            <div className="hidden">
              <label className="block mb-3 font-semibold text-gray-700 text-md">
                Permissions
              </label>
              <div className="flex p-3 border border-gray-300 rounded-xl gap-x-3 bg-base-white">
                <div>
                  <p className="mb-1 font-semibold text-gray-700 text-md">
                    Include conversion tools
                  </p>
                  <p className="text-sm font-gray-600">
                    Save the configuration settings of conversion tools in a
                    template to be reused for any page generated from it.
                  </p>
                </div>
                <Toggle
                  checked={isIncludeConversionsValue}
                  onChange={onChangeIsIncludeConversions}
                />
              </div>
            </div>
            <div>
              <label className="block mb-3 font-semibold text-gray-700 text-md">
                Who can use the template
              </label>
              <div className="flex items-center gap-4">
                {Object.values(TemplateSharingAccess).map((value) => (
                  <RadioButtonForPermissions
                    name="sharing-access"
                    key={value}
                    value={value}
                    onChange={() => setSharingAccess(value)}
                    defaultChecked={value === sharingAccessValue}
                  />
                ))}
              </div>
            </div>
            <div>
              <label className="block mb-3 font-semibold text-gray-700 text-md">
                Template card cover background
              </label>
              <div className="flex gap-3">
                {Object.values(TemplateBgColor).map((value) => (
                  <RadioButtonForBgColor
                    name="bg-color"
                    colorValue={value}
                    key={value}
                    value={value}
                    onChange={() => {
                      setBgColor(value);
                    }}
                    defaultChecked={value === bgColorValue}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 flex w-full gap-3 p-6 border-t border-gray-200 bg-base-white">
            <Button
              fullWidth
              color="secondary"
              variant="text"
              type="button"
              onClick={handleSubmit(handleSaveTemplateAsDraft)}
              loading={isDraftLoading && isLoading}
            >
              Save as draft
            </Button>
            <Button
              onClick={handleSubmit(handleSaveTemplateAsReady)}
              type="submit"
              color="primary"
              variant="text"
              loading={isReadyLoading && isLoading}
              fullWidth
            >
              Save Template
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
