import { Template } from '@distribute/shared/types';
import { Button, Input, Modal } from '../../../../shared/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { templatesModel } from '../../model';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { redirectActions } from '../../../../entities/history';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  template: Template;
  returnToWorkspace?: boolean;
};

type DeleteTemplateModalForm = {
  confirmText: string;
  id: string;
};

const CONFIRMATION_TEXT = 'CONFIRM';

const validationSchema = object().shape({
  confirmText: string()
    .required(`Type ${CONFIRMATION_TEXT} is required`)
    .oneOf(
      [CONFIRMATION_TEXT, CONFIRMATION_TEXT.toLowerCase()],
      `Please type ${CONFIRMATION_TEXT} to confirm.`
    ),
});

export const DeleteTemplateConfirmationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  template,
  returnToWorkspace = false,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    templatesModel.selectors.selectTemplateIsDeleting
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DeleteTemplateModalForm>({
    resolver: yupResolver(validationSchema),
  });

  const onModalClose = () => {
    reset();
    onClose();
  };

  const onSubmitCallBack = () => {
    onModalClose();
    dispatch(templatesModel.actions.setPreviewMode(false));
    dispatch(templatesModel.actions.setCurrentTemplate(undefined));
    dispatch(templatesModel.actions.setCurrentTemplateContentId(undefined));

    if (returnToWorkspace) {
      dispatch(redirectActions.toWorkspaceByBackUrl());
    }
  };

  const handleFormSubmit = () => {
    dispatch(
      templatesModel.actions.deleteTemplate({
        id: template.id,
        callback: onSubmitCallBack,
      })
    );
  };

  return (
    <Modal
      onClose={onModalClose}
      isOpen={isOpen}
      title="Delete Template"
      dialogClassName="!z-100"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="destructive"
          variant="text"
          loading={isLoading}
        >
          Delete Forever
        </Button>
      }
    >
      <div className="text-s font-normal text-gray-600 mb-5">
        The “{template.name}” template and its content will be deleted
        permanently.
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mt-2">
          <Input
            autoComplete="off"
            label={`Confirm by typing ${CONFIRMATION_TEXT} below`}
            {...register('confirmText')}
            isError={!!errors.confirmText}
            messageText={errors.confirmText?.message}
            type="text"
          />
        </div>
      </form>
    </Modal>
  );
};
